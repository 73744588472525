.caseTitle {
  margin: 0 auto;
  max-width: calc(40vw - 90px);
  text-align: center;
  font-size: 40px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
}

.caseTitle span {
  display: inline;
}
